.match-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.match-popup {
  background-color: #222;
  border: 3px solid #444;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  color: #ddd;
}

.close-button-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #ff3333;
  cursor: pointer;
}

.match-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 140px;
  /*margin-bottom: 20px;*/
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.team-emblem-container {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-bottom: 10px;*/
}

.team-emblem {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.team-popup h2 {
  font-size: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #ff3333;
}

.score {
  font-size: 36px;
  font-weight: bold;
  color: #ff3333;
}

.score-separator {
  margin: 0 10px;
}

.match-info p {
  margin-top: 20px;
  flex-direction: column;
}

.match-events {
  margin-top: 20px;
}

.match-events h3 {
  color: #ff3333;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.event {
  margin: 10px 0;
  padding: 5px;
  background-color: #333;
  border-radius: 5px;
}

.event-time {
  font-weight: bold;
  margin-right: 10px;
  color: #ff3333;
}

.assist {
  font-size: 0.9em;
  color: #aaa;
}

.booking {
  color: #ffff33;
}

@media (max-width: 768px) {
  .match-popup {
    width: 95%;
    padding: 15px;
  }

  .team-logo-container {
    width: 60px;
    height: 60px;
  }

  .team h2 {
    font-size: 0.9rem;
  }

  .score, .score-separator {
    font-size: 28px;
  }
}

/*.event.substitution {*/
/*  color: #4CAF50;*/
/*}*/

.event.substitution .event-out {
  color: #ff3333;
}

.event.substitution .event-in {
  color: #4CAF50;
}


.event-team-emblem {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}
