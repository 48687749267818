@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

body {
  font-family: 'Special Elite', cursive;
  background-color: #1a1a1a;
  color: #ddd;
  margin: 0;
  padding: 20px;
  line-height: 1.6;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100"><rect width="100" height="100" fill="%231a1a1a"/><circle cx="50" cy="50" r="40" fill="none" stroke="%23333" stroke-width="0.5"/></svg>');
}

.container {
  max-width: 400px;
  margin: 0 auto;
  background-color: #222;
  border: 3px solid #444;
  padding: 20px;
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100"><rect width="100" height="100" fill="%23222"/><path d="M0 0 L100 100 M100 0 L0 100" stroke="%23333" stroke-width="0.5"/></svg>');
  opacity: 0.1;
  pointer-events: none;
}

h1 {
  color: #ff3333;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: 20px 0;
  border-bottom: 2px solid #444;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  letter-spacing: 2px;
  animation: flicker 2s infinite;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

input {
  background-color: #333;
  border: 1px solid #555;
  color: #ddd;
  padding: 10px;
  font-family: 'Special Elite', cursive;
  width: 100%;
  box-sizing: border-box;
}

button {
  background-color: #ff3333;
  color: #1a1a1a;
  border: none;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Special Elite', cursive;
  width: 100%;
}

button:hover {
  background-color: #ddd;
  color: #ff3333;
}

.error {
  color: #ff3333;
  font-size: 0.9em;
  margin-top: 5px;
}

.success {
  color: #33ff33;
  font-size: 0.9em;
  margin-top: 5px;
}

.signup-link {
  text-align: center;
  margin-top: 20px;
}

.signup-link a {
  color: #ff3333;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

@keyframes flicker {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

@media (max-width: 480px) {
  body {
    padding: 10px;
  }

  .container {
    padding: 15px;
    border-width: 2px;
  }

  h1 {
    font-size: 24px;
    padding: 15px 0;
  }

  form {
    gap: 15px;
  }

  input, button {
    font-size: 16px;
    padding: 12px;
  }

  .error, .success, .signup-link {
    font-size: 0.85em;
  }
}